<template>
    <List ref="list" :moduleId="moduleId" :threeType="threeType" :resourceName="resourceName"></List>
</template>

<script>
    import List from '../components/list' // 列表
    export default {
        components: {List},
        data() {
            return {
                moduleId: "958676692447002624",
                threeType: 2,
                resourceName: "音频资源"
            }
        },
        mounted() {
            this.$refs.list.init(this.threeType)
        },
        methods: {}
    }
</script>
